import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/preact';
import { useState } from 'preact/compat';

import { route } from '../../../helpers/router';

import { setTempSrs } from '../srs/actions';

import SVG from '../../../components/common/SVG';
import TextComponent from '../../../components/common/textComponents';
import Intl from '../../../components/common/intl';
import Button from '../../../components/common/button';
import Header from '../../../components/header';

import style from './style.scss';

const STAND = 'stand';
const BOX = 'box';
const SUITE = 'suite';
const CUSTOM_TYPE_1 = 'custom_type_1';
const CUSTOM_TYPE_2 = 'custom_type_2';
const CUSTOM_TYPE_3 = 'custom_type_3';

const venueTypes = {
  stadium: '/assets/svg/stadium-find-me.svg',
  arena: '/assets/svg/arena-find-me.svg',
  zone: '/assets/svg/circle-zone.svg',
};

const SeatOption = (props) => {
  const {
    seatTypes,
    savedSrsType,
    setSeatType,
    venueType = 'stadium',
  } = props;

  const [activeSeatType, setActiveSeatType] = useState();

  const buttonClickHandler = (type) => {
    setActiveSeatType(type);
    setTimeout(() => {
      if (type === savedSrsType) {
        return route('/findme/confirm');
      }

      setSeatType({ type });
      return route('/findme/srs/section');
    }, 500);
  };

  const buttonPicker = (seatType) => {
    switch (seatType) {
    case STAND: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === STAND}
          onClick={() => buttonClickHandler(STAND)}
          className={`${STAND}-btn`}
        >
          <Intl textKey="I18N_SRS_STAND">In a Seat</Intl>
        </Button>
      );
    }

    case BOX: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === BOX}
          onClick={() => buttonClickHandler(BOX)}
          className={`${BOX}-btn`}
        >
          <Intl textKey="I18N_SRS_BOX">Box</Intl>
        </Button>
      );
    }

    case SUITE: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === SUITE}
          onClick={() => buttonClickHandler(SUITE)}
          className={`${SUITE}-btn`}
        >
          <Intl textKey="I18N_SRS_SUITE">In a Suite</Intl>
        </Button>
      );
    }

    case CUSTOM_TYPE_1: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === CUSTOM_TYPE_1}
          onClick={() => buttonClickHandler(CUSTOM_TYPE_1)}
          className={`${CUSTOM_TYPE_1}-btn`}
        >
          <Intl textKey="I18N_SRS_CUSTOM_TYPE_1">Custom Type 1</Intl>
        </Button>
      );
    }

    case CUSTOM_TYPE_2: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === CUSTOM_TYPE_2}
          onClick={() => buttonClickHandler(CUSTOM_TYPE_2)}
          className={`${CUSTOM_TYPE_2}-btn`}
        >
          <Intl textKey="I18N_SRS_CUSTOM_TYPE_2">Custom Type 2</Intl>
        </Button>
      );
    }

    case CUSTOM_TYPE_3: {
      return (
        <Button
          next
          secondary
          bold
          active={activeSeatType === CUSTOM_TYPE_3}
          onClick={() => buttonClickHandler(CUSTOM_TYPE_3)}
          className={`${CUSTOM_TYPE_3}-btn`}
        >
          <Intl textKey="I18N_SRS_CUSTOM_TYPE_3">Custom Type 3</Intl>
        </Button>
      );
    }

    default: {
      return null;
    }
    }
  };
  const buttons = [];

  seatTypes.forEach((seatType) => {
    const button = buttonPicker(seatType);
    if (button) {
      buttons.push(button);
    }
  });

  return (
    <Fragment>
      <Header />

      <div className={style.page}>
        <SVG src={venueTypes[venueType]} pageImage />
        <TextComponent element="h2" className={style.centerText} center>
          <Intl textKey="I18N_SRS_WHERE_ARE_YOU">
            Where are you sitting?
          </Intl>
        </TextComponent>
        {buttons}
      </div>
    </Fragment>
  );
};

export default connect(
  ({ event: { venueType } }) => ({ venueType }),
  () => ({ setSeatType: setTempSrs }),
)(SeatOption);
